// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-apply-agency-tsx": () => import("./../../../src/pages/apply/agency.tsx" /* webpackChunkName: "component---src-pages-apply-agency-tsx" */),
  "component---src-pages-apply-company-tsx": () => import("./../../../src/pages/apply/company.tsx" /* webpackChunkName: "component---src-pages-apply-company-tsx" */),
  "component---src-pages-apply-mall-tsx": () => import("./../../../src/pages/apply/mall.tsx" /* webpackChunkName: "component---src-pages-apply-mall-tsx" */),
  "component---src-pages-contact-download-tsx": () => import("./../../../src/pages/contact/download.tsx" /* webpackChunkName: "component---src-pages-contact-download-tsx" */),
  "component---src-pages-contact-form-tsx": () => import("./../../../src/pages/contact/form.tsx" /* webpackChunkName: "component---src-pages-contact-form-tsx" */),
  "component---src-pages-functions-environment-analysis-tsx": () => import("./../../../src/pages/functions/environmentAnalysis.tsx" /* webpackChunkName: "component---src-pages-functions-environment-analysis-tsx" */),
  "component---src-pages-functions-path-plot-tsx": () => import("./../../../src/pages/functions/pathPlot.tsx" /* webpackChunkName: "component---src-pages-functions-path-plot-tsx" */),
  "component---src-pages-functions-usage-analysis-tsx": () => import("./../../../src/pages/functions/usageAnalysis.tsx" /* webpackChunkName: "component---src-pages-functions-usage-analysis-tsx" */),
  "component---src-pages-functions-user-tsx": () => import("./../../../src/pages/functions/user.tsx" /* webpackChunkName: "component---src-pages-functions-user-tsx" */),
  "component---src-pages-functions-ux-analysis-tsx": () => import("./../../../src/pages/functions/uxAnalysis.tsx" /* webpackChunkName: "component---src-pages-functions-ux-analysis-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */)
}

