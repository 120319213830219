import React from 'react';
import { ThemeProvider, Global } from '@emotion/react';
import { CacheProvider } from '@emotion/react';

import { myCache } from './create-emotion-cache';

import { theme, defaultStyle } from './src/themes/GlobalStyle';
import 'prismjs/themes/prism-tomorrow.css';

export const wrapRootElement = ({ element }) => (
  <CacheProvider value={myCache}>
    <ThemeProvider theme={theme}>
      <Global styles={defaultStyle} />
      {element}
    </ThemeProvider>
  </CacheProvider>
);
